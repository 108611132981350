import Vue from 'vue'
import Router from 'vue-router'
// import Home from '../views/Home.vue'


Vue.use(Router)

const routes = [
      {
        path: '/',
        name: 'home',
        component: () => import('../components/Home.vue')
      },
      {
        path: '/conditions-generales',
        name: 'conditions',
        component: () => import('../components/Conditions.vue')
      },
      {
        path: '/commande-et-devis',
        name: 'devis',
        component: () => import('../components/Devis.vue')
      },
      {
      path: '/about',
      name: 'about',
      component: () => import('../components/About.vue')
      },
      {
      path: '*',
      name: '404',
      component: () => import('../components/NotFound.vue')
      }
    ]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router