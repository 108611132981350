<template>
  <v-app>
    <v-main>
      <NavBar
      :nav-links="navLinks"
      />
      <!-- <router-view/> -->
      <transition name='fade' mode='out-in'>
        <router-view></router-view>
      </transition>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import NavBar from './components/NavBar'
import Footer from './components/Footer'



export default {
  name: 'App',

  components: {
    NavBar,
    Footer
  },

  data: () => ({
    navLinks: [
      {
        text:'Accueil',
        path:'/'    
      },
      {
        text:'À propos',
        path:'/about'    
      },
      {
        text:'Conditions',
        path:'/conditions'    
      },
      {
        text:'Devis',
        path:'/devis'    
      }
    ]
  })
};
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
