<template>
  <v-footer
  padless
  color='grey lighten-1'
  >
    <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">
    
<v-card
      flat
      class=" text-center"
      color='grey lighten-4'
    >
      <v-card-text>
        <div class="text-center video-seb video-responsive hidden-md-and-up">
        <span class="first"><strong>TVA:</strong> BE 0741 978 635</span><br>
        <span class="second"><strong>IBAN:</strong> BE47 0013 9323 8480</span><br>
        <span class="third"><strong>BIC:</strong> GEBABEBB</span>
        </div>
        <div class="text-center video-seb hidden-sm-and-down">
        <span class="first"><strong>TVA:</strong> BE 0741 978 635</span>
        <span class="second"><strong>IBAN:</strong> BE47 0013 9323 8480</span>
        <span class="third"><strong>BIC:</strong> GEBABEBB</span>
        </div>

        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text >
        <strong>SF ELEC</strong> — {{ new Date().getFullYear() }}
        <v-btn
        @click="top"
        elevation="0"
        fab
        >
        <v-icon color="blue" class="up-icon" large>keyboard_arrow_up</v-icon>
        </v-btn> 
      </v-card-text>
    </v-card>

  </v-footer>
</template>

<script>
export default {
  name: 'Footer',

  components: {
    
  },
  methods:{
      top(){
             window.scrollTo(0,0);
           }
  },
  goUp(){window.scrollTo({
  top: 0,
  left: 0,
  behavior: 'smooth'
  });
  }
}
</script>

<style>

.v-card{
  width: 100%;
}

.up-icon {
  background-color: transparent !important;
}

.first, .second, .third{
  margin:0px 25px 0px 25px;
}

.up-icon:hover{
  color:violet;
}
</style>