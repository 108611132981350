<template>
  <div>  
    <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200;300;400;500&display=swap" rel="stylesheet">   

    <!--navbar-->
    <v-app-bar
      color="white"
      dense
      dark
      height='70'
      elevation='1'
      class="hidden-sm-and-down"
    >
      <v-app-bar-nav-icon tile class='logo-nav'><router-link to='/'><v-img contain class ='logo-nav' alt="sfelec-logo" src=".././assets/logocorrige.png"/></router-link></v-app-bar-nav-icon>
      

      <v-toolbar-title class='title-nav'>SF ELEC</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        class= "btn-navbar"
        href=""
        text
      >
      <router-link to='/' class="txt-navbar">
      Accueil
      </router-link>
      </v-btn>

      <v-btn
        class= "btn-navbar"
        href=""
        text
      >
      <router-link to='/commande-et-devis' class="txt-navbar">
      Commande et Devis
      </router-link>
      </v-btn>

      <v-btn
        class= "btn-navbar"
        href=""
        text
      >
      <router-link to='/conditions-generales' class="txt-navbar">
      Conditions générales
      </router-link>
      </v-btn>

      <v-btn
        class= "btn-navbar"
        href=""
        text
      >
      <router-link to='/about' class="txt-navbar">
      À propos
      </router-link>
      </v-btn>

      <!-- <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="n in 5"
            :key="n"
            @click="() => {}"
          >
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      
    </v-app-bar>

  <!--mininavbar-->
    <v-app-bar
      app
      elevation='2'
      height='70'
      color=rgb(255,255,255)
      class="hidden-md-and-up nav-hamburger mini-nav"
    >   
    <v-app-bar-nav-icon :ripple='false' class='logo-mini-nav' v-bind="size"><router-link to='/'><v-img contain class ='logo-mini-nav' alt="sfelec-logo" src=".././assets/logocorrige.png"/></router-link></v-app-bar-nav-icon>
   
    <v-toolbar-title class="title-nav">SF ELEC</v-toolbar-title>
    
    <div class="info-navbar">
      <v-icon class="phone-icon-navbar" color="blue" small>phone</v-icon> 
      <a class="phone-texte-navbar" href="tel:+32476265226">0476 26 52 26</a>
    </div>

    <!-- <v-spacer></v-spacer> -->
    
    <!-- <div class="hidden-md-and-up"> -->
    <v-app-bar-nav-icon 
      @click="drawer = !drawer"
      class="hidden-md-and-up menu-hamburger"
      >
    </v-app-bar-nav-icon>
</v-app-bar>


<v-sheet class="overflow-hidden">  
<v-navigation-drawer
      v-model="drawer"
      temporary
      right
      fixed
>
      <v-list-item>
        <v-list-item-avatar
        :ripple='false'
        tile
        >
          <v-img 
          contain 
          class='logo-mini-nav-burger' alt="sfelec-logo" src=".././assets/logoseul.png"/>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="title-mini-nav">SF ELEC</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for='navLink in navLinks'
          :key = 'navLink.text'          
        >
          <v-list-item-content>
            <v-list-item-title class='mini-nav-text'><router-link class='mini-nav-text' :to='navLink.path'>{{navLink.text}}</router-link></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</v-sheet>

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
export default {
  name: 'NavBar',
  components: {
    
  },
  data: () => ({
    navLinks: [
      {
        labelKey: 'nav.home',
        text:'Accueil',
        path:'/'    
      },
      {
        labelKey: 'nav.devis',
        text:'Commande et devis',
        path:'/commande-et-devis'    
      },
      {
        labelKey: 'nav.conditions',
        text:'Conditions',
        path:'/conditions-generales'    
      },
      {
        labelKey: 'nav.about',
        text:'À propos',
        path:'/about'    
      }
    ],
    drawer: null
  }),
    computed: {
      size () {
        const size = {xs:'x-small',sm:'large',md:'x-large', lg:'x-large',xl:'x-large'}[this.$vuetify.breakpoint.name];
        return size ? { [size]: true } : {}
      }
    }
}
</script>

<style>

main.v-main {
 padding: 0px 0px 0px !important;
}

element.style {
    padding: 0px 0px 0px !important;
}

/* *{
  padding: 0;
}  */

.icone{
  color:black;
}

.txt-navbar {
  color:black !important;
  background-color: transparent !important;
  text-decoration: none;
}

.txt-navbar:before{
  background-color: transparent !important;
}

.txt-navbar:hover:before{
  background-color: transparent !important;
}

.txt-navbar:hover{
  color:#116FFF !important;
}

.mini-nav-text{
  margin-top: 15px;
  color:black !important;
  background-color: transparent !important;
  text-decoration: none;
  text-align: center;
  font-family: 'Libre Franklin', sans-serif;
}

.title-mini-nav {
    color:rgb(71, 72, 77);
    font-size: 1.3rem !important;
    font-family: 'Jura', sans-serif;
    font-weight: 700;
}

.logo-mini-nav{
    /* flex: 1 0 auto; */
      width: 13em;
      height: 13em;
      margin-left: 125px;
      margin-bottom: 5px;
      margin-right: 40px;
}

a{
  text-decoration: none;
}

a:focus{
  color:#116FFF !important;
}

.logo-nav{
      /* width: 150px 15%; */
      /* height: 90px 20%; */
      width: 13em;
      height: 13em;
      margin-left: 125px;
      margin-bottom: 8px;    
}

.phone-texte-navbar{
  font-size:15px;
  margin-left: 5px;
  margin-right: 5px;
  color:#4b4b4b !important;
}

.title-nav{
  visibility: hidden;
}

@media (min-width: 768px) and (max-width: 768px) {
    .menu-hamburger{
      margin-left: 35%;
    }

    .info-navbar{
      margin-left: 20%;
    }

    .logo-mini-nav{
      width: 11em;
      height: 11em;
      margin-left: 125px;
      margin-bottom: 5px;      
    }
}

@media (min-width: 360px) and (max-width: 414px){
  .menu-hamburger{
      margin-left: 12%;
    }

  .phone-texte-navbar{
    font-size:13px;
  }
}

@media (min-width: 320px) and (max-width: 320px){
 /* iphone5 */
 .logo-mini-nav{
   margin-left:16px !important;
   margin-right: 0px !important;
 }
}

@media (min-width: 414px) and (max-width: 414px){
  .menu-hamburger{
      margin-left: 20%;
    }
}
</style>